import React from 'react'

function HowHashoutHelpedALeadingMedicalDevicesManufacturerGetTheBestOurOfAem() {
    return (
        <div style={{ height: "100vh" }}>
            <object data="../../How-Hashout-helped-a-leading-medical-devices-manufacturer-get-the-best-our-of-AEM.pdf#toolbar=0" type="application/pdf" width="100%" height="100%">
                <a href="../../How-Hashout-helped-a-leading-medical-devices-manufacturer-get-the-best-our-of-AEM.pdf"></a>
            </object>
        </div>
    )
}

export default HowHashoutHelpedALeadingMedicalDevicesManufacturerGetTheBestOurOfAem